// For using by legacy code after building with vite
// TODO Need refactoring
window.key = key;
window.updateCart = updateCart;
window.addtocart = addtocart;
window.addtocartpop = addtocartpop;
window.addtocartpopup = addtocartpopup;
window.clearall = clearall;
window.delitemfrombasket = delitemfrombasket;
window.changeitemamount = changeitemamount;
window.cartsort = cartsort;
window.ajaxUpdateAllAddToCartButtons = ajaxUpdateAllAddToCartButtons;

$(function () {
  $(document).on('click', '.cart_sort span', function (ev) {
    ev.preventDefault();
    $(this).addClass('toggled');
    $(this).next().slideDown();
  });

  $(document).on('click', '.cart_sort span.toggled', function (ev) {
    ev.preventDefault();
    $(this).parent().find('.toggled').removeClass('toggled');
    $(this).next().slideUp();
  });

  $(document).on('click', '.cart-dropdown li a', function () {
    $('.toggled').trigger('click');
    const txt = $(this).text();
    $('.cart_sort span').text(txt);
  });

  $(document).on('click', '.product_cart_input_change, .form-control', function () {
    $(this).attr('placeholder', ' ');
  });

  $(document).on('blur', '#phonechange', function () {
    $(this).attr('placeholder', 'XXXXXXXXXX');
  });
  $(document).on('blur', '#phonephis', function () {
    $(this).attr('placeholder', 'XXXXXXXXXX');
  });
  $(document).on('blur', '#phonefirm', function () {
    $(this).attr('placeholder', 'XXXXXXXXXX');
  });
});

$(document).click(function (event) {
  if (!$(event.target).closest('.search_window').length && !$(event.target).closest('.header-search-btn').length) {
    if ($('.search_window ').is(':visible')) {
      $('.search_window ').fadeOut('fast');
      $('.header-search-btn').removeClass('gray');
    }
  }
});

(function ($) {
  $.confirm = function (params) {
    if ($('#confirmOverlay').length) {
      return false;
    }

    let buttonHTML = '';
    $.each(params.buttons, function (name, obj) {
      buttonHTML += '<a href="#" class="button ' + obj['class'] + '">' + name + '<span></span></a>';

      if (!obj.action) {
        obj.action = function () {};
      }
    });

    const markup = [
      '<div id="confirmOverlay">',
      '<div id="confirmBox">',
      '<h1>',
      params.title,
      '</h1>',
      '<p>',
      params.message,
      '</p>',
      '<div id="confirmButtons">',
      buttonHTML,
      '</div></div></div>',
    ].join('');

    $(markup).hide().appendTo('body').fadeIn();

    let buttons = $('#confirmBox .button'),
      i = 0;

    $.each(params.buttons, function (name, obj) {
      buttons.eq(i++).click(function () {
        obj.action();
        $.confirm.hide();
        return false;
      });
    });
  };

  $.confirm.hide = function () {
    $('#confirmOverlay').fadeOut(function () {
      $(this).remove();
    });
  };

  $.fn.setCursorPosition = function (pos) {
    if ($(this).get(0).setSelectionRange) {
      $(this).get(0).setSelectionRange(pos, pos);
    } else if ($(this).get(0).createTextRange) {
      const range = $(this).get(0).createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  };
})(jQuery);

function key(event) {
  return 'which' in event ? event.which : event.keyCode;
}

/**
 * Update all add to cart buttons text with new amount.
 * Getting products in cart by ajax request.
 * Using after ajax requests.
 */
function ajaxUpdateAllAddToCartButtons() {
  $.ajax({
    dataType: 'json',
    url: '/update-cart',
    success: function (data) {
      updateAllAddToCartButtons(data.products);
    },
  });
}

/**
 * Update all add to cart buttons text with new amount if this product is in cart.
 * @param products Products in cart (object from backend)
 */
function updateAllAddToCartButtons(products) {
  const $productCards = $('.product_li');

  $productCards.each(function () {
    const productId = +$(this).data('id');

    if (productId in products) {
      const amount = +products[productId].count;
      updateAddToCartButtonText(this, amount);
    }
  });
}

/**
 *  Update text in add to cart button for a new amount. Update only if changed.
 * @param productEl Product card DOM element
 * @param amount Amount of products in cart
 */
function updateAddToCartButtonText(productEl, amount) {
  const $product = $(productEl);
  const $button = $product.find('.product_cart_add');

  amount = +amount;
  const amountText = amount + (amount < 1000 ? ' шт.' : '');

  const oldButtonHtml = $button.html();
  const newButtonHtml = `<img src="/images/korzina.png"> <span>${amountText}</span>`;

  if (newButtonHtml !== oldButtonHtml) {
    $button.html(newButtonHtml);
  }
}

function updateCart() {
  $.ajax({
    dataType: 'json',
    url: '/update-cart',
    success: function (data) {
      updateAllAddToCartButtons(data.products);

      const $headerCount = $('#headercount');
      $headerCount.text(data.count);
      $('#headersumm').html(parseFloat(data.summ).toFixed(2) + ' <span>a</span>');

      $('#footercount').html('<span>Корзина:</span> ' + data.footercount);
      $('#footersumm').html('<span>Сумма:</span> ' + parseFloat(data.summ).toFixed(2) + '<i>a</i>');

      if (!$headerCount.hasClass('active')) {
        $headerCount.addClass('active');
      }

      if (data.count > 0) {
        if (!$headerCount.hasClass('active')) {
          $headerCount.addClass('active');
        }
      } else {
        if ($headerCount.hasClass('active')) {
          $headerCount.removeClass('active');
        }
      }

      if (data.auth !== '0') {
        $('.header-userbox a').attr('data-target', '#cart');
        const $headerUserboxSpan = $('.header-userbox span');

        if ($headerUserboxSpan.hasClass('header-userbox_img_grey')) {
          $headerUserboxSpan.removeClass('header-userbox_img_grey');
          $headerUserboxSpan.addClass('header-userbox_img');
        }
      } else {
        $('.header-userbox a').attr('data-target', '#login');
        const $headerUserboxSpan = $('.header-userbox span');

        if ($headerUserboxSpan.hasClass('header-userbox_img')) {
          $headerUserboxSpan.removeClass('header-userbox_img');
          $headerUserboxSpan.addClass('header-userbox_img_grey');
        }
      }
    },
  });
}

function addtocart(id, price, input) {
  let amm;
  let add = 0;
  const $amountId = $('#amount_' + id);

  if (input == 1) {
    if ($amountId.val() > 0) {
      amm = $amountId.val();
      add = 1;
    }
  }
  if (input == 2) {
    if ($amountId.val() > 0) {
      amm = $amountId.val();
      add = 1;
    } else if ($amountId.val() == 0) {
      amm = 1;
      add = 1;
    }
  }

  if (add == 1) {
    $.ajax({
      dataType: 'json',
      url: '/add/' + id,
      data: 'price=' + price + '&amount=' + amm,
      success: function (data) {
        updateAllAddToCartButtons(data.products);

        const $headerCount = $('#headercount');
        $headerCount.text(data.count);
        $('#headersumm').html(parseFloat(data.summ).toFixed(2) + ' <span>a</span>');

        $('#footercount').html('<span>Корзина:</span> ' + data.footercount);
        $('#footersumm').html('<span>Сумма:</span> ' + parseFloat(data.summ).toFixed(2) + '<i>a</i>');

        const $fixedCart = $('.fixed-cart');
        if (!$fixedCart.hasClass('active')) {
          $fixedCart.addClass('active');
        }

        if (!$headerCount.hasClass('active')) {
          $headerCount.addClass('active');
        }

        $amountId.val('');

        setTimeout(function () {
          $('.add-to-cart').hide();
        }, 1000);
      },
    });
  }
}

function addtocartpop(id, price, input) {
  let amm;
  let add = 0;

  const $amountPopId = $('#amount_pop_' + id);

  if (input == 1) {
    if ($amountPopId.val() > 0) {
      amm = $amountPopId.val();
      add = 1;
    }
  }

  if (input == 2) {
    if ($amountPopId.val() > 0) {
      amm = $amountPopId.val();
      add = 1;
    } else if ($amountPopId.val() == 0) {
      amm = 1;
      add = 1;
    }
  }

  if (add == 1) {
    $.ajax({
      dataType: 'json',
      url: '/add/' + id,
      data: 'price=' + price + '&amount=' + amm,
      success: function (data) {
        updateAllAddToCartButtons(data.products);

        const $headercount = $('#headercount');
        $headercount.text(data.count);
        $('#headersumm').html(parseFloat(data.summ).toFixed(2) + ' <span>a</span>');

        $('#footercount').html('<span>Корзина:</span> ' + data.footercount);
        $('#footersumm').html('<span>Сумма:</span> ' + parseFloat(data.summ).toFixed(2) + '<i>a</i>');

        const $fixedCart = $('.fixed-cart');
        if (!$fixedCart.hasClass('active')) {
          $fixedCart.addClass('active');
        }

        if (!$headercount.hasClass('active')) {
          $headercount.addClass('active');
        }

        $amountPopId.val('');
      },
    });
  }
}

$('.product_cart_add').on('click', function (params) {
  let addToCartElement = $('<div class="add-to-cart">Добавлено в корзину<b></b></div>');

  $(this).closest('.product_cart').append(addToCartElement);

  setTimeout(function () {
    $('.add-to-cart').remove();
  }, 1000);
});

function addtocartpopup(id, price, input) {
  let amm;
  let add = 0;

  const $amountPopupId = $('#amountpopup_' + id);
  if (input == 1) {
    if ($amountPopupId.val() > 0) {
      amm = $amountPopupId.val();
      add = 1;
    }
  }

  if (input == 2) {
    if ($amountPopupId.val() > 0) {
      amm = $amountPopupId.val();
      add = 1;
    } else if ($amountPopupId.val() == 0) {
      amm = 1;
      add = 1;
    }
  }

  if (add == 1) {
    $.ajax({
      dataType: 'json',
      url: '/add/' + id,
      data: 'price=' + price + '&amount=' + amm,
      success: function (data) {
        updateAllAddToCartButtons(data.products);

        const $headerCount = $('#headercount');
        $headerCount.text(data.count);
        $('#headersumm').html(parseFloat(data.summ).toFixed(2) + ' <span>a</span>');

        $('#footercount').html('<span>Корзина:</span> ' + data.footercount);
        $('#footersumm').html('<span>Сумма:</span> ' + parseFloat(data.summ).toFixed(2) + '<i>a</i>');

        const $fixedCart = $('.fixed-cart');
        if (!$fixedCart.hasClass('active')) {
          $fixedCart.addClass('active');
        }

        if (!$headerCount.hasClass('active')) {
          $headerCount.addClass('active');
        }

        $amountPopupId.val(1);

        $().toastmessage('showSuccessToast', 'Добавлено в корзину');
      },
    });
  }
}

function clearall() {
  if ($('.table-cart').length && $('.mob-cart-wrapper').length) {
    $.confirm({
      title: 'Вы подтверждаете очищение корзины?',
      message: 'При очищении корзины ее содержимое будет удалено.',
      buttons: {
        Очистить: {
          class: 'blue',
          action: function () {
            $.ajax({
              dataType: 'json',
              url: '/clearall',
              success: function (data) {
                $('#headercount').text(data.count);
                $('#headersumm').html(parseFloat(data.summ).toFixed(2) + ' <span>a</span>');

                $('#footercount').html('<span>Корзина:</span> ' + data.footercount);
                $('#footersumm').html('<span>Сумма:</span> ' + parseFloat(data.summ).toFixed(2) + '<i>a</i>');

                $('.fixed-cart').removeClass('active');

                $('#fullsumm').text('0 руб.');
                $('#mob_fullsumm').text('0 руб.');

                $('.table-cart').remove();
                $('.mob-cart-wrapper').remove();

                $('#ctbefore').before("<div style='line-height:40px;margin-left:18px'><b>Корзина пуста</b></div>");

                $('#headercount').removeClass('active');

                $().toastmessage('showSuccessToast', 'Корзина очищена.');
              },
            });
          },
        },
        Отмена: {
          class: 'gray',
          action: function () {},
        },
      },
    });
  }
}

function delitemfrombasket(id) {
  $.confirm({
    title: 'Вы подтверждаете удаление товара?',
    message: '',
    buttons: {
      Удалить: {
        class: 'blue',
        action: function () {
          $.ajax({
            dataType: 'json',
            url: '/deletefrombasket/' + id,
            success: function (data) {
              $('#headercount').text(data.count);
              $('#headersumm').html(parseFloat(data.summ).toFixed(2) + ' <span>a</span>');

              $('#footercount').html('<span>Корзина:</span> ' + data.footercount);
              $('#footersumm').html('<span>Сумма:</span> ' + parseFloat(data.summ).toFixed(2) + '<i>a</i>');

              $('#fullsumm').text(parseFloat(data.summ).toFixed(2) + ' руб.');

              $('#delhref_' + id)
                .closest('tr')
                .remove();
              $('#delhref_' + id)
                .closest('.mob-cart-item')
                .remove();

              if (data.empty == 1) {
                $('.table-cart').remove();
                $('#ctbefore').before("<div style='line-height:40px;margin-left:18px'><b>Корзина пуста</b></div>");
                $('#headercount').removeClass('active');
                $('.fixed-cart').removeClass('active');
              }

              $().toastmessage('showSuccessToast', 'Товар удален из корзины.');
              $('#mob_fullsumm').text(parseFloat(data.summ).toFixed(2) + ' руб.');
            },
          });
        },
      },
      Отмена: {
        class: 'gray',
        action: function () {},
      },
    },
  });
}

function changeitemamount(id) {
  if ($('#changeamount_' + id).val() > 0) {
    $.ajax({
      dataType: 'json',
      url: '/changeitemamount/' + id,
      data: 'amount=' + $('#changeamount_' + id).val(),
      success: function (data) {
        $('#headercount').text(data.count);
        $('#headersumm').html(parseFloat(data.summ).toFixed(2) + ' <span>a</span>');

        $('#footercount').html('<span>Корзина:</span> ' + data.footercount);
        $('#footersumm').html('<span>Сумма:</span> ' + parseFloat(data.summ).toFixed(2) + '<i>a</i>');

        $('#fullsumm').text(parseFloat(data.summ).toFixed(2) + ' руб.');
        $('#mob_fullsumm').text(parseFloat(data.summ).toFixed(2) + ' руб.');

        $('#full_sum_tovar_' + id).text(parseFloat(data.summtovar).toFixed(2));
        $('#mob_full_sum_tovar_' + id).text(parseFloat(data.summtovar).toFixed(2));
      },
    });
  }
}

function cartsort(sort) {
  if ($('#sortparam').val() != sort) {
    $('#sortparam').val(sort);
    $.ajax({
      url: '/cartsort',
      data: 'sort=' + sort,
      success: function (data) {
        const temp_arr_html = data.split('<REPLACEMEPLEAS>');
        $('#carttovars').html(temp_arr_html[0]);
        $('.mob-cart-wrapper').html(temp_arr_html[1]);
        $('.amountMinus').on('click', function () {
          const $input = $(this).parent().find('input');

          if ($input.val() != 1) {
            $input.val(parseInt($input.val()) - 1);
            $input.change();
          }

          return false;
        });

        $('.amountPlus').on('click', function () {
          const $input = $(this).parent().find('input');
          $input.val(parseInt($input.val()) + 1);
          $input.change();
          return false;
        });
      },
    });
  }
}

document.addEventListener('DOMContentLoaded', function () {
  updateCart();
});
