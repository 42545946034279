// Functions from vendoes/modal.js

// For using by legacy code after building with vite
// TODO Need refactoring
// TODO Move all user function from this file
window.loginmodalenter = loginmodalenter;
window.getsizedgoods = getsizedgoods;
window.getsizedgoodswithparams = getsizedgoodswithparams;
window.getsizednews = getsizednews;
window.setkatalog = setkatalog;
window.showpagedigitalswithurl = showpagedigitalswithurl;
window.showpageframes = showpageframes;
window.openshortcartsearch = openshortcartsearch;
window.goPage = goPage;
window.fastsearch = fastsearch;
window.liFormat = liFormat;
window.selectItem = selectItem;
window.selectItemtop = selectItemtop;
window.selectItemtop2 = selectItemtop2;
window.getCookie = getCookie;
window.setCookie = setCookie;

function loginmodalenter() {
  let val = $('#login-phone').val().replace(/[\D]/g, '');

  if ((val.length === 11 && val[0] == 7) || (val.length === 10 && val[0] != 7)) {
    if (val.length === 11 && val[0] == 7) val = val.substr(1);

    $.ajax({
      url: '/login',
      type: 'post',
      data: { login: val, passw: $('#passwlogin').val() },
      success: function (data) {
        if (data == 'yes') {
          window.location.href = '/personal';
        } else {
          $('#wronglogindiv').html('Введенные данные не верны.');
        }
      },
    });
  } else {
    $('#wronglogindiv').html('Введенные данные не верны.');
  }
}

function getsizedgoods() {
  const param = 'size=' + $('#sizedselect').val();
  $.fn.yiiListView.update('tovarsframes', {
    data: param,
  });
}

function getsizedgoodswithparams() {
  const param = 'size=' + $('#sizedselect').val();
  let pages = '';
  const selected = $('#tovarsframes').find('.pagination-block .pager ul.pagination .selected a').text();
  if (selected != '1') pages = '&Goods_page=' + selected;
  $.fn.yiiListView.update('tovarsframes', {
    data: param,
    url:
      '/categories/setkatalog?showtovars=on' +
      pages +
      '&ajax=tovarsframes&type=' +
      $('#activetypefour').val() +
      '&series=' +
      $('#activekatalogseries').val() +
      '&framescolors=' +
      $('#activeframescolors').val() +
      '&framestypes=' +
      $('#activeframestypes').val() +
      '&digitalscolors=' +
      $('#activedigitalscolors').val() +
      '&digitalstypes=' +
      $('#activedigitalstypes').val(),
  });
}

function getsizednews() {
  const param = 'size=' + $('#sizedselect').val();
  $.fn.yiiListView.update('newsframes', { data: param });
}

/**************************************** katalog *************************************************/

function setkatalog(id, filtr, typetovar) {
  // typefour, katalogseries, framescolors, framestypes, digitalscolors, digitalstypes
  if ($('#active' + filtr).val() != id) {
    $('#filter_' + filtr + '_' + $('#active' + filtr).val()).removeClass('active');
    $('#filter_' + filtr + '_' + id).addClass('active');
    $('#active' + filtr).val(id);

    if (filtr == 'typefour') {
      $('#activetypetovar').val(typetovar);

      $('#activeframescolors').val(0);
      $('#activeframestypes').val(0);
      $('#activedigitalscolors').val(0);

      if ((id == 5559 || id == 0) && $('#activedigitalstypes').val() != 0) {
        $('#filter_digitalstypes_' + $('#activedigitalstypes').val()).removeClass('active');
      }

      $('#activedigitalstypes').val(0);
    }

    if (filtr == 'digitalstypes') {
      $('#activetypetovar').val(typetovar);
      $('#activetypefour').val(5558); //5559 - возможно везде одинаково (на данный момент)
      $('#activeframescolors').val(0);
      $('#activeframestypes').val(0);

      // сохранить цвет и обнулить
      // ***
      var savecolor = $('#activedigitalscolors').val();

      $('#activedigitalscolors').val(0);

      $('#filter_typefour_5559').removeClass('active');
      $('#filter_typefour_0').removeClass('active');
    }

    if (filtr == 'katalogseries') {
      // сохранить цвет и обнулить
      // ***
      var savecolor = $('#activedigitalscolors').val();

      $('#activeframescolors').val(0);
      $('#activeframestypes').val(0);
      $('#activedigitalscolors').val(0); ///// $("#activedigitalstypes").val(0);

      if ($('#activetypefour').val() == 5559) $('#filter44').text('Все цвета рамок');
      if ($('#activetypefour').val() == 5558) $('#filter44').text('Все цвета механизмов');
    }

    if (filtr == 'digitalscolors') {
      $('#filter44').text($('#filter_digitalscolors_' + id + ' a').text());
    }

    $.ajax({
      url: '/categories/setkatalog',
      dataType: 'json',
      type: 'post',
      data: {
        type: $('#activetypefour').val(),
        series: $('#activekatalogseries').val(),
        framescolors: $('#activeframescolors').val(),
        framestypes: $('#activeframestypes').val(),
        digitalscolors: $('#activedigitalscolors').val(),
        digitalstypes: $('#activedigitalstypes').val(),
      },
      success: function (data) {
        var showtovars = 1;
        if (data.seriesData) {
          $('.filter_box.qq2').html(data.seriesData);
        }
        if (
          $('#activetypefour').val() != 0 &&
          (filtr == 'typefour' || filtr == 'katalogseries' || filtr == 'digitalstypes')
        ) {
          if (
            ($('#activetypetovar').val() == 'ram' && data.filtrframes.length > 0) ||
            ($('#activetypetovar').val() == 'mech' && data.filtrdigitals.length > 0)
          ) {
            if ($('#fornewfiltr').css('display') == 'none') $('#fornewfiltr').css('display', 'block');
            if ($('#activetypetovar').val() == 'ram') {
              $('#fornewfiltr').html(data.filtrframes);
            } else {
              $('#fornewfiltr').html(data.filtrdigitals);

              // проверить есть ли цвет и заново функцию с цветом запустить
              // ***
              if (savecolor != 0) {
                if ($('#filter_digitalscolors_' + savecolor).length) {
                  setkatalog(savecolor, 'digitalscolors', 'all');
                  showtovars = 0;
                }
              }
            }
          }
        }

        if ($('#activetypefour').val() == 0) {
          $('#fornewfiltr').html('');
          $('#fornewfiltr').css('display', 'none');
        }

        if (data.cond.length > 0 && showtovars == 1) {
          const loadingEl = document.querySelector('#loading-screen');

          $.ajax({
            url: '/categories/setkatalogtovars',
            type: 'post',
            data: { cond: data.cond, bigcond: data.bigcond },
            beforeSend: function () {
              if (loadingEl === null) {
                console.error('Not found loading el');
              } else {
                $(loadingEl).css('display', 'flex').fadeIn(150);
              }
            },
            success: function (data) {
              $('#plentytovars').html(data);
              // Trigger not working without setTimeout hack.
              setTimeout(function () {
                $('.product_img_change img').unveil(200);
                $(document).trigger('lookup');
              }, 50);

              window.ajaxUpdateAllAddToCartButtons();
            },
            complete: function () {
              if (loadingEl !== null) {
                $(loadingEl).fadeOut(150);
              }
            },
          });
        } else if (data.cond.length == 0) {
          $('#plentytovars').html("<p style='margin-left:5px'>Товары не найдены.</p>");
        }
      },
    });
  }
}

/*************popup****************/

function showpagedigitalswithurl(color, cat, idseries) {
  $.ajax({
    url: '/goods/showpagedigitals',
    data: 'color=' + color + '&category=' + cat,
    success: function (data) {
      var link = $('#filter_katalogseries_' + idseries + ' a').attr('href');
      if (link) {
        window.location.href = link;
      } else {
        var link = $('#forcrossing').val();
        if (link) {
          window.location.href = link;
        }
      }
    },
  });
}

function showpageframes(color, url) {
  $.ajax({
    url: '/goods/showpageframes',
    data: 'color=' + color,
    success: function (data) {
      if (url) {
        window.location.href = url;
      }
    },
  });
}

function openshortcartsearch(idtovar, index) {
  $('.overlay').addClass('visible');
  $('.popup-wrapper').addClass('visible');

  $.ajax({
    url: '/tovarpopupsearch/' + idtovar,
    success: function (data) {
      if (data != '404') {
        $('#popuptovar').html(data);

        var img = $('#popuptovar .product-detail_img_scaled img');
        var imgWidth = img.width();
        var imgHeight = img.height();

        if (imgWidth > imgHeight) {
          img.css({ width: '100%' });
        } else if (imgWidth < imgHeight) {
          img.css({ height: '100%' });
        } else {
          img.css({ height: '100%', width: '100%' });
        }

        var indexprev = index - 1;
        var indexnext = index + 1;

        var tovarprev = $('#tovar_' + indexprev)
          .find('.product_img a')
          .attr('rel');
        var tovarnext = $('#tovar_' + indexnext)
          .find('.product_img a')
          .attr('rel');

        //prev
        if (typeof tovarprev == 'undefined') {
          indexprev = $('#tovarsframes').find('ul.items li:last .product_n a').attr('rel');
          tovarprev = $('#tovar_' + indexprev)
            .find('.product_img a')
            .attr('rel');
        }

        //next
        if (typeof tovarnext == 'undefined') {
          indexnext = $('#tovarsframes').find('ul.items li:first .product_n a').attr('rel');
          tovarnext = $('#tovar_' + indexnext)
            .find('.product_img a')
            .attr('rel');
        }

        $('.prev-popup-btn').css('display', 'block');
        $('.prev-popup-btn').attr('onclick', 'openshortcartsearch(' + tovarprev + ',' + indexprev + ')');

        $('.next-popup-btn').css('display', 'block');
        $('.next-popup-btn').attr('onclick', 'openshortcartsearch(' + tovarnext + ',' + indexnext + ')');
      } else {
        $('#popuptovar').html('<section class="product-detail" style="padding:15px">Товар не найден.</section>');
        $('.prev-popup-btn').css('display', 'none');
        $('.next-popup-btn').css('display', 'none');
      }
    },
  });
}

/*************\popup***************/

function goPage(sPage) {
  window.location.href = sPage;
}

function fastsearch(useLoadingScreen = true) {
  if ($('#fastandclever').val() != '' && $('#fastandclever').val().length > 2) {
    const loadingEl = document.querySelector('#loading-screen');

    $.ajax({
      url: '/categories/fastsearch',
      data: { query: $('#fastandclever').val() },
      beforeSend: function () {
        if (loadingEl === null || !useLoadingScreen) {
          console.error('Not found loading el');
        } else {
          $(loadingEl).css('display', 'flex').fadeIn(150);
        }
      },
      success: function (data) {
        $('#plentytovars').html(data);
        $('#fastandclever').blur();
        // Trigger not working without setTimeout hack.
        setTimeout(function () {
          $('.product_img_change img').unveil(200);
          $(document).trigger('lookup');
        }, 50);

        window.ajaxUpdateAllAddToCartButtons();
      },
      complete: function () {
        if (!(loadingEl === null || !useLoadingScreen)) {
          $(loadingEl).fadeOut(150);
        }
      },
    });
  }
}

function liFormat(row, i, num) {
  var result = row[0];
  return result;
}

function selectItem(li) {
  if (li == null) var sValue = 'Ничего не выбрано!';
  if (!!li.extra) var sValue = li.extra[2];
  else var sValue = li.selectValue;

  sValue = sValue.replace(/<b>(.+?)<\/b>/g, '$1');

  $('#fastandclever').val(sValue);
  fastsearch();
}

function selectItemtop(li) {
  if (li == null) var sValue = 'Ничего не выбрано!';
  if (!!li.extra) var sValue = li.extra[2];
  else var sValue = li.selectValue;

  sValue = sValue.replace(/<b>(.+?)<\/b>/g, '$1');

  $('#queryfastclever').val(sValue);
  $('#topcleversearch').submit();
}

function selectItemtop2(li) {
  if (li == null) var sValue = 'Ничего не выбрано!';
  if (!!li.extra) var sValue = li.extra[2];
  else var sValue = li.selectValue;

  sValue = sValue.replace(/<b>(.+?)<\/b>/g, '$1');

  $('#queryfastclever2').val(sValue);
  $('#topcleversearch2').submit();
}

$(function () {
  if ($('#fastandclever').length) {
    $('#fastandclever').autocomplete('/categories/fastandclever', {
      delay: 500,
      minChars: 2,
      matchSubset: true,
      autoFill: false,
      matchContains: false,
      cacheLength: 1,
      mustMatch: false,
      selectFirst: false,
      formatItem: liFormat,
      onItemSelect: selectItem,
    });
  }

  if ($('#queryfastclever').length) {
    $('#queryfastclever').autocomplete('/categories/fastandclever', {
      delay: 500,
      minChars: 2,
      matchSubset: true,
      autoFill: false,
      matchContains: false,
      cacheLength: 1,
      mustMatch: false,
      selectFirst: false,
      formatItem: liFormat,
      onItemSelect: selectItemtop,
      width: 330,
    });
  }

  if ($('#queryfastclever2').length) {
    $('#queryfastclever2').autocomplete('/categories/fastandclever', {
      delay: 500,
      minChars: 2,
      matchSubset: true,
      autoFill: false,
      matchContains: false,
      cacheLength: 1,
      mustMatch: false,
      selectFirst: false,
      formatItem: liFormat,
      onItemSelect: selectItemtop2,
      resultsClass: 'ac_results sec',
      width: 330,
    });
  }

  $(window).on('scroll', function () {
    if ($(window).width() <= 1149) {
      return;
    }

    const $filtersBlock = $('.js_filtersBlock');
    const $fornewfiltr = $filtersBlock.find('#fornewfiltr');

    if (!$fornewfiltr.length) {
      return;
    }

    const $slideFilter = $filtersBlock.find('.products-category_filter');
    const $productCategoryBox = $filtersBlock.find('.products-category_box');
    const $productCategoryList = $filtersBlock.find('.products-category_list');
    const $filterBoxes = $fornewfiltr.find('.filter_box');

    const slideFilterPositionTop = $slideFilter.offset().top + $slideFilter.height();
    const scrollTopPos = $(window).scrollTop();

    const filterBlockHeight = $productCategoryBox.height();
    const productContainerHeight = $productCategoryList.height();
    const needToMakeSticky = filterBlockHeight < productContainerHeight;
    const amountOfBottomFilters = $filterBoxes.length;
    const heightPercentForFilter = Math.floor(100 / amountOfBottomFilters);

    if (needToMakeSticky && scrollTopPos >= slideFilterPositionTop) {
      $fornewfiltr.css({ position: 'fixed', top: 0 });
      $fornewfiltr.css({ width: '210px' });
      $filterBoxes.css({ 'max-height': `${heightPercentForFilter}%` });
    } else {
      $fornewfiltr.css({ position: 'relative', top: 'auto' });
      $filterBoxes.css({ 'max-height': '294px' });
      $fornewfiltr.css({ 'max-height': 'max-content' });
    }
  });

  $('.footer_links_ul li').hover(
    function () {
      $('ul', this).fadeIn(200);
    },
    function () {
      $('ul', this).fadeOut(200);
    }
  );

  /*************popup****************/
  $('.close-popup-btn').on('click', function () {
    $('.overlay').removeClass('visible');
    $('.popup-wrapper').removeClass('visible');
    $('#popuptovar').html('');
  });
  /*************\popup***************/

  if ($('.seria-brangs').length) $('.seria-brangs .img img').unveil(200);
});

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.matchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (css) {
    var node = this;

    while (node) {
      if (node.matches(css)) return node;
      else node = node.parentElement;
    }

    return null;
  };
}

if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (fn, scope) {
    for (var i = 0, len = this.length; i < len; ++i) {
      fn.call(scope, this[i], i, this);
    }
  };
}

if (typeof NodeList !== 'undefined' && NodeList.prototype && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

document.addEventListener('DOMContentLoaded', function () {
  if (typeof fadeOutnojquery === 'function') {
    window.addEventListener = window.addEventListener || window.attachEvent;

    window.addEventListener('load', execFadeOut);

    function execFadeOut() {
      setTimeout(function () {
        fadeOutnojquery(hellopreloader);
      }, 1000);
    }
  }

  document.querySelectorAll('input[type=tel]').forEach(function (item) {
    item.classList.remove('smallerinput');

    if (item.previousElementSibling.tagName === 'SPAN')
      item.previousElementSibling.parentNode.removeChild(item.previousElementSibling);

    let char = '';

    item.addEventListener('input', phoneMask);

    if (item.value) item.dispatchEvent(new Event('input'));

    function phoneMask(e) {
      if (e.data && e.data === '7' && char) {
        this.value = '';

        char = '';
      }

      char = e.data && e.data === '+' && this.value.length === 1 ? e.data : '';

      this.value = this.value.replace(/[^\d]/g, '');

      let start = 2;

      if (/^[87]/.test(this.value)) {
        this.value = this.value.replace(/^[87]/, '+7');

        //start = 2;
      } else {
        this.value = '+7' + this.value;
      }

      if (this.value[start] && this.value[start] !== '(')
        this.value = this.value.substr(0, start) + '(' + this.value.substr(start);
      if (this.value[start + 4] && this.value[start + 4] !== ')')
        this.value = this.value.substr(0, start + 4) + ')' + this.value.substr(start + 4);
      if (this.value[start + 8] && this.value[start + 8] !== '-')
        this.value = this.value.substr(0, start + 8) + '-' + this.value.substr(start + 8);
      if (this.value[start + 11] && this.value[start + 11] !== '-')
        this.value = this.value.substr(0, start + 11) + '-' + this.value.substr(start + 11);
      if (this.value[start + 14]) this.value = this.value.substr(0, start + 14);
    }
  });

  let inputCount = document.querySelectorAll('input.mob-items-count');

  if (inputCount) {
    inputCount.forEach(function (item) {
      item.style.paddingRight = '8px';
      item.style.paddingLeft = '8px';
      item.style.width = '40px';
    });
  }
});

function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : null;
}

function setCookie(name, value, options) {
  if (typeof options == undefined) options = {};

  options = {
    path: '/',
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}
